<template>
  <div>
    <label class="vs-input--label">{{ label }}</label>
    <v-select
      v-model="localValue"
      style="background-color: white"
      :reduce="(option) => option.value"
      :options="options"
      placeholder="TODAS"
      :appendToBody="appendToBody"
      :dir="$vs.rtl ? 'rtl' : 'ltr'"
      :multiple="multiple"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    label: {
      default () {
        return 'Categoria'
      }
    },
    multiple: {},
    appendToBody: {}
  },
  data () {
    return {
      options: [
        { label: 'A', value: 'A' },
        { label: 'B', value: 'B' },
        { label: 'C', value: 'C' },
        { label: 'D', value: 'D' },
        { label: 'E', value: 'E' },
        { label: 'ACC', value: 'ACC' }
      ]
    }
  },
  computed: {
    localValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

