var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-row",
    { staticClass: "w-full" },
    [
      _c(
        "vs-col",
        { staticClass: "w-full" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(_vm._s(_vm.label)),
          ]),
          _c("v-select", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate.initial",
                value: "required",
                expression: "'required'",
                modifiers: { initial: true },
              },
            ],
            staticClass: "fix-vselect",
            attrs: {
              multiple: _vm.multiple,
              clearable: false,
              reduce: (option) => option.value,
              options: _vm.options,
              appendToBody: _vm.appendToBody,
              placeholder: _vm.placeholder,
              "data-vv-as": "Funcionário",
              name: "single",
              dir: _vm.$vs.rtl ? "rtl" : "ltr",
            },
            scopedSlots: _vm._u([
              {
                key: "no-options",
                fn: function ({}) {
                  return [_vm._v(" Nenhum resultado encontrado. ")]
                },
              },
            ]),
            model: {
              value: _vm.localValue,
              callback: function ($$v) {
                _vm.localValue = $$v
              },
              expression: "localValue",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }