<template>
  <vs-row class="w-full">
    <vs-col class="w-full">
      <label class="vs-input--label">{{label}}</label>
      <v-select
        v-model="localValue"
        :multiple="multiple"
        :clearable="false"
        :reduce="(option) => option.value"
        :options="options"
        :appendToBody="appendToBody"
        :placeholder="placeholder"
        class="fix-vselect"
        data-vv-as="Funcionário"
        v-validate.initial="'required'"
        name="single"
        :dir="$vs.rtl ? 'rtl' : 'ltr'"
      >
        <template #no-options="{}"> Nenhum resultado encontrado. </template>
      </v-select>
      <!-- <span class="text-danger text-sm" v-show="errors.has('single')">{{
        errors.first('single')
      }}</span> -->
    </vs-col>
  </vs-row>
</template>

<script>
// Store Module
import axios from '@/axios.js'
import _ from 'lodash'

export default {
  props: {
    value: {},
    multiple: {},
    roles: {},
    appendToBody: {},
    label: {
      default () {
        return 'Funcionário'
      }
    },
    placeholder: {},
    useId: {}
  },
  data () {
    return {
      single: null,
      options: [],
      allEmployees: []
    }
  },
  computed: {
    localValue: {
      get () {
        if (this.useId) return this.value
        if (Array.isArray(this.value) && this.value.length) return this.value.map(e => e.id)
        return this.value && this.value.id ? this.value.id : null
      },
      set (value) {
        if (this.useId) {
          this.$emit('input', value)
        } else if (this.multiple) this.$emit('input', this.allEmployees.filter(e => value.includes(e.id)))
        else this.$emit('input', this.allEmployees.find(e => e.id === value))
      }
    }
  },
  methods: {

    fetchOptions (search) {
      axios
        .get(`${process.env.VUE_APP_API_URL}/employees`)
        .then(
          function (response) {
            this.options = []
            this.allEmployees = response.data
            if (this.roles && this.roles.length) {
              response.data.forEach(e => {
                if (e.roles.find(e => this.roles.includes(e.name))) {
                  this.options.push({
                    value: e.id,
                    label: e.name
                  })
                }
              })
            } else {
              response.data.forEach(e => {
                this.options.push({
                  value: e.id,
                  label: e.name
                })
              })
            }

          }.bind(this)
        )
        .catch(function (error) {
          console.error(error)
        })
    },
    isValid () {
      this.$validator.validateAll()
      return !this.errors.has('single')
    }
  },
  created () {
    this.debouncedGetSearch = _.debounce(this.fetchOptions, 600)
    if (this.options.length === 0) {
      this.debouncedGetSearch()
    }
  }
}
</script>
